import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import RequireAuth from './components/RequireAuth';
import MessagePage from './components/MessagePage';

const App = () => {  
  return (
    <Routes>
      <Route path="/message" element={<MessagePage />} />
      {/* <Route path="/login" element={<Login />} /> */}
      <Route path="*" element={<RequireAuth />}>
        <Route index element={<Dashboard />} />
        {/* Pastikan menambahkan rute untuk semua endpoint di sini */}
      </Route>
    </Routes>
  );
};

// export default App;

// import MessagePage from './components/MessagePage';
// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/message" element={<MessagePage />} />
//         <Route path="*" element={<Navigate to="/message" replace />} />
//       </Routes>
//     </Router>
//   );
// };

export default App;