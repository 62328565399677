import React from 'react';

const MessagePage = () => {
  return (
    <div style={styles.container}>
      <img src={`${window.location.origin}/logo192_otbot.png`} alt="Logo" style={styles.logo} />
      <h1 style={styles.title}>Pengumuman Penting</h1>
      <p style={styles.message}>
      Kami segenap team onetobot meminta maaf 🙏 <br/>
Website <strong> user.onetobot.com </strong>kami tutup karena ada banyak tindak kecurangan oleh member dalam menggunakan lisensi, <br/>
Silakan jika ingin pindah lisensi atau ganti password lisensi chat admin di jam operasional 
        <strong> 09:00 SD 21:00 (WIB)</strong>.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
  },
  logo: {
    width: '100px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '2rem',
    marginBottom: '1rem',
  },
  message: {
    fontSize: '1.25rem',
    lineHeight: '1.5',
  },
};

export default MessagePage;
