import React, { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { verifyToken } from '../api/licenseAPI';

const RequireAuth = () => {
    // const { auth } = useAuth();
    const auth = JSON.parse(localStorage.getItem("user"))
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() => {
        const checkAuthentication = async () => {
            if (auth && auth.token) {
                try {
                    const response = await verifyToken(auth.token);
                    console.log('data respon');
                    console.log(response?.data?.success);
                    if (response.data && response.data.success) {
                        setIsAuthenticated(true);
                    } else {
                        console.log("token salah");
                        setIsAuthenticated(false);
                    }
                } catch (error) {
                    console.error("Error verifying token:", error);
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(false);
            }
        };

        checkAuthentication();
    }, [auth]);

    // return isAuthenticated ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
    return <Navigate to="/message" state={{ from: location }} replace />;

};

export default RequireAuth;
