// DataTable.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
// import { ConfirmPopup } from 'primereact/confirmpopup';
import { Button } from 'primereact/button';
import moment from "moment"
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeicons/primeicons.css';
import { Panel } from 'primereact/panel';
// import { verifyToken } from '../api/licenseAPI';
import { Checkbox } from "primereact/checkbox";
import { Password } from 'primereact/password';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ConfirmDialog } from 'primereact/confirmdialog';
 import { Carousel } from 'primereact/carousel';
// import { Tag } from 'primereact/tag';
// import { ProductService } from './service/ProductService';

const Dashboard = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [software, setSoftware] = useState(null);
  const [baseUrl, setBaseUrl] = useState('');

  const [password, setPassword] = useState('');
  const [visibleResetMac, setVisibleResetMac] = useState(false);
  const [visibleResetPwd, setVisibleResetPwd] = useState(false);

 const [products, setProducts] = useState([]);
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

  //   const getSeverity = (product) => {
  //       switch (product.inventoryStatus) {
  //           case 'INSTOCK':
  //               return 'success';

  //           case 'LOWSTOCK':
  //               return 'warning';

  //           case 'OUTOFSTOCK':
  //               return 'danger';

  //           default:
  //               return null;
  //       }
  //   };
    
  useEffect(() => {
    // RefreshData()
    // ProductService.getProductsSmall().then((data) => setProducts(data.slice(0, 9)));
    const userData = JSON.parse(localStorage.getItem("user"))
    if (userData){
      //setUser(userData.member);
      RefreshData(userData.member);
      setSoftware(userData.software);
      // verifyToken(userData)
      // .then(
      //   response => {
      //     if(response){
      //       if(response.data.success){
      //         console.log("ini dari login:" + response.data.success);
      //         navigate('/');
      //       }
      //       else
      //       {
      //         navigate('/');
      //         console.log("ini dari login:");
      //       }
      //     }
      //   }
      // )
      // .catch(
      //   response => {
          
      //     navigate('/login');
      //         console.log("not authorize");
      //   }
      // );
    }
    else
    {
      navigate('/login');
    }
   
  }, [navigate])

const handleLogout = () => {
  localStorage.removeItem("user")
  navigate('/login');
};

const handleRefresh = () => {
  window.location.reload();
};

const maskString = (str) => {
  if (str.length >= 5) {
    const visibleChars = str.slice(0, 5); // Mengambil lima karakter pertama
    const hiddenChars = '*'.repeat(str.length - 5); // Mengganti karakter sisanya dengan "*"
    return visibleChars + hiddenChars;
  }

  return '-';
};

const RefreshData = async(member) => {
  const urlString = process.env.REACT_APP_API_URL;
  const url = new URL(urlString);
  setBaseUrl(url.origin)
  await axios({
    method: 'get',
    url: process.env.REACT_APP_API_URL + '/' + member.id,
  })
  .then(
    response => {
      const resp = response.data.license;
      setUser(resp)
    }
  );

  await axios({
    method: 'get',
    url: process.env.REACT_APP_API_URL + '/advertise',
  })
  .then(
    response => {
      const resp = response.data.advertiseList;
      setProducts(resp)
    }
  );
}

const handleDeleteMac = async() => {
  try {
    const dataPost = {
      softwareId : user.softwareId,
      mac_address : user.mac_address,
    }

    console.log(dataPost);
    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/deleteMac',
      data: dataPost,
      headers: {
          'Content-Type': 'application/json',
        },
    })

    // const response = dispatch(login(email, password));
    if (response.data &&response.data.success) {
      console.log("berhasil")
      toast.success('perangkat terdaftar '+ user.email + ' berhasil di reset!', {
        position: 'top-right',
        autoClose: 3000, // Durasi tampilan toast (ms)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      RefreshData(user)
    } else {
      setError('Tambah Data gagal.');
    }  
  } catch (error) {
    setError('Tambah Data gagal.');
  }
};

const handleSave = async() => {
  // onSave(editedData);
  try {
    const dataPost = {
      password: password,
      expired: user.expired,
      status: user.status,
      isTrial: user.isTrial
    }

    console.log(dataPost)
    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/' + user.id,
      data: dataPost,
      headers: {
          'Content-Type': 'application/json',
        },
    })

    // const response = dispatch(login(email, password));
    if (response.data &&response.data.success) {
      console.log("berhasil");
      toast.success('Data berhasil disimpan!', {
        position: 'top-right',
        autoClose: 3000, // Durasi tampilan toast (ms)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });     
      handleLogout(); 
    } else {
      setError('Ubah Data gagal.');
    }  
  } catch (error) {
    setError('Ubah Data gagal.');
  }
};
const generateWhatsAppLink = (phoneNumber, message) => {
  const encodedMessage = encodeURIComponent(message);
  return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
};

const reject = () => {
  // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
};
const [checked, setChecked] = useState(false);

const productTemplate = (advertise) => {
  return (
    <div className="container">
<div className="border-1 ">
  <div className='text-center surface-border border-round m-2 py-5 px-3'>
          <div className="mb-3">
            <a href={advertise.link} target="_blank" rel="noopener noreferrer">
              <img src={`${baseUrl}/uploads/advertise/${advertise.fileName}`} alt={advertise.name} className="w-6 shadow-2" />
            </a>
          </div>
          <div className="container has-text-centered">
              <h1 className="is-size-5"><strong>{advertise.name}</strong> </h1>
              {/* <h6 className="mt-0 mb-3">${advertise.price}</h6> */}
              {/* <Tag value={product.inventoryStatus} severity={getSeverity(product)}></Tag> */}
              <div className="mt-5 flex flex-wrap gap-2 justify-content-center">
                <a href={advertise.link} target="_blank">
                  <Button icon="pi pi-search" className="p-button" label='INFO LEBIH LENGKAP'/>
                </a>
              </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

  return (
    <div className="container mt-5">
       <div className="container pl-6 pr-6">
        <div className="columns is-centered">
          <div className="column is-10">
          <h1 className="title">Hallo, {user ? user.username: ""}!</h1>
          {error && (
                    <div className="notification is-danger">
                      <button className="delete" onClick={() => setError(null)}></button>
                      {error}
                    </div>
                  )}
            </div>
            <div className="column">
            <div className="field is-grouped">
              <div className="control">
                <Button icon="pi pi-refresh" className="button has-text-white mb-3" style={{ backgroundColor: 'var(--cyan-900)'}}
                  onClick={() => handleRefresh()} label='Refresh'></Button>
              </div>
              <div className="control">
                <Button icon="pi pi-sign-out" className="button has-background-danger has-text-white mb-3"
                  onClick={() => handleLogout()} label='keluar'></Button>
              </div>
            </div>
          </div>
        </div>
       </div>
      
      <Panel header="Infromasi Pengguna">
      <div className="columns">
        <div className="column">
        <section className="block">
            <p><strong>Tools :</strong></p>
            <p>{software ? software.name: ""}</p>
          </section>
          <section className="block">
            <p><strong>User Name :</strong></p>
            <p>{user ? user.username: ""}</p>
          </section>
          
        </div>
        <div className="column">
          <section className="block">
            <p><strong>Email :</strong></p>
            <p>{user ? user.email: ""}</p>
          </section>
          <section className="block">
            <div className="field">
            <label className="label" htmlFor="password"><div className="control">
                  <Checkbox inputId="password" onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                  <label htmlFor="password" className="ml-2">Ganti Password</label>
                </div></label>
            <div className="control">
              <div className="field is-grouped">
                <div className="control">
                  <Password 
                    type="password"
                    id="password"
                    disabled = {!checked}
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    toggleMask 
                  />
                </div>
                <div className="control">
                  <Button icon="pi pi-check" disabled = {!checked} 
                    onClick={() => setVisibleResetPwd(true)} label='Simpan'></Button>
                    <ConfirmDialog visible={visibleResetPwd} onHide={() => setVisibleResetPwd(false)} message={"Apakah anda yakin akan menggati Password '" + (user ? user.email : "") +"' ? " }
                header="Konfirmasi" icon="pi pi-exclamation-triangle" accept={() => handleSave()} reject={reject} />
                </div>
              </div>
              
              </div>
            </div>
          </section>
        </div>
        <div className="column">
        <section className="block">
          <p><strong>Masa Aktif Sampai :</strong></p>
          <div className="columns">
            <div className="column">
              <p>{user ? moment(user.expired).format('DD MMMM YYYY'): ""}</p>
            </div>
            <div className="column">
                  <Button icon="pi pi-sync"
                    onClick={() => window.open(generateWhatsAppLink('6285717636868', `Hallo, admin onetobot saya ingin perpanjang akun ${user.email} untuk tools ${software.name}`), '_blank')} label='Perpanjang'></Button>
              </div>
          </div>
        </section>
          <section className="block">
            <p><strong>Perangkat terdaftar :</strong></p>
            <div className="columns">
              <div className="column">
                <p>{user && user.mac_address ? maskString(user.mac_address): ""}</p>
              </div>
              <div className="column">
                  <Button icon="pi pi-undo"
                    disabled = {user && user.mac_address?  (user.mac_address.length === 0 ? true : false) : false}
                    onClick={() => setVisibleResetMac(true)} label='Reset'></Button>

                <ConfirmDialog visible={visibleResetMac} onHide={() => setVisibleResetMac(false)} message={"Apakah anda yakin akan mereset perangkat terdaftar '" + (user ? user.email : "") +"' ? " }
                header="Konfirmasi" icon="pi pi-exclamation-triangle" accept={() => handleDeleteMac()} reject={reject} />
              </div>
            </div>
          </section>
          
        </div>
      </div>
      </Panel>
      {products && (
          <Panel className="card mt-5">
            <div className="container" style={{ width: '60%' }}>
              <p className="is-size-4 has-text-centered pt-4" style={{ color: 'var(--surface-600)' }}>Beberapa Produk Onetobot Robot Marketing Dan Ecourse<strong style={{ color: 'var(--cyan-900)' }}>, Untuk Membantu Bisnis Anda!!</strong></p>
            </div>
            <Carousel value={products} numScroll={1} numVisible={3} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} />
          </Panel>
      )}
     
    </div>
  );
};

export default Dashboard;
